@import url(https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  min-height: 100vh;
  background: #fff;
  font-family: 'Barlow Semi Condensed', sans-serif;
}
.title_defualt{
  font-size: 3rem;
  width: 100%;
  text-align: left;
  margin-top: 50px;
}

a{
  text-decoration: none;
}
.banner_wrapper {
  min-height: 500px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 5px solid #f50;
  padding: 50px 0;
  background: linear-gradient(to right, rgba(0, 102, 255, 0.274), rgba(255, 51, 0, 0.473)), url(/static/media/bgh.753e88bd.jpeg);
  background-size: cover;
  background-position: center;
}

.banner_wrapper .banner_container {
  min-height: 500px;
  height: auto;
  width: 1366px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}

.banner_wrapper .banner_container .content_head {
  width: 70%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner_wrapper .banner_container .content_head .headline {
  font-size: 3.2rem;
  text-transform: uppercase;
}

.banner_wrapper .banner_container .content_head .headline_sub {
  text-transform: uppercase;
}

@media (min-width: 0px) and (max-width: 992px) {
  .banner_wrapper .banner_container {
    width: 90%;
  }
  .banner_wrapper .banner_container .content_head {
    width: 90%;
    margin-top: 50px;
  }
  .banner_wrapper .banner_container .content_head .headline {
    font-size: 2rem;
  }
}
/*# sourceMappingURL=style.css.map */
.educativo_wrapper {
  min-height: 500px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  background: #fff;
  padding: 50px 0;
}

.educativo_wrapper .educativo_container {
  min-height: 300px;
  height: auto;
  width: 1366px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.educativo_wrapper .educativo_container .headline_educativo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 30px;
  min-height: 200px;
  height: auto;
}

.educativo_wrapper .educativo_container .headline_educativo .title_educativo {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  align-self: center;
  margin: 0 auto;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.educativo_wrapper .educativo_container .headline_educativo .title_educativo span {
  height: 60px;
  width: 40px;
  background: rgba(255, 85, 0, 0.411);
  display: block;
  position: absolute;
  top: -20%;
  left: 50px;
}

.educativo_wrapper .educativo_container .headline_educativo .content {
  width: 80%;
}

.educativo_wrapper .educativo_container .image_container {
  min-height: 200px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.educativo_wrapper .educativo_container .image_container .title_image {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  align-self: center;
  margin: 0 auto;
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 30px;
}

@media (min-width: 0px) and (max-width: 992px) {
  .educativo_wrapper .educativo_container {
    width: 80%;
  }
  .educativo_wrapper .educativo_container .image_container img {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1250px) {
  .educativo_wrapper .educativo_container {
    width: 80%;
  }
  .educativo_wrapper .educativo_container .image_container img {
    width: 100%;
  }
}
/*# sourceMappingURL=style.css.map */
.final_wrapper {
  min-height: 100px;
  height: auto;
  padding: 50px 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.final_wrapper .final_container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.final_wrapper .final_container .text {
  font-size: 1.7rem;
}

.final_wrapper .final_container .text a {
  color: #111;
  font-weight: 600;
  text-transform: uppercase;
  transition: all ease-in-out .3s;
}

.final_wrapper .final_container .text a:hover {
  color: #f50;
}
/*# sourceMappingURL=style.css.map */
.footer_wrapper {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 5px solid #f50;
}

.footer_wrapper .footer_container {
  min-height: 100px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_wrapper .footer_container .left {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 0px) and (max-width: 992px) {
  .footer_wrapper .footer_container {
    min-height: 100px;
    height: auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 992px) and (max-width: 1250px) {
  .footer_wrapper .footer_container {
    min-height: 100px;
    height: auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/*# sourceMappingURL=style.css.map */
.menu_wrapper {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  transition: 0.3s;
}

.menu_wrapper .logo {
  width: 20%;
}

.menu_wrapper .nav {
  width: 60%;
}

.menu_wrapper .contato {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu_wrapper .contato .circle_icon {
  height: 40px;
  width: 40px;
  background: #f50;
  border-radius: 50px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu_wrapper .contato .circle_icon i {
  color: #fff;
}

.menu_wrapper .contato .tel {
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  padding: 14px 30px;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #fff !important;
  color: #fff !important;
  font-weight: 400;
  border: 1px solid #f50;
  color: #f50;
}

.menu_wrapper .contato .tel:hover {
  background: #fff !important;
  color: #f50 !important;
}

.menu_wrapper .contato .scrollTel {
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  padding: 14px 30px;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #f50 !important;
  color: #f50 !important;
  font-weight: 400;
}

.menu_wrapper .contato .scrollTel:hover {
  background: #f50 !important;
  color: #fff !important;
}

.back {
  background: #fff !important;
  box-shadow: 0px 5px 15px 0px #2223252d;
  transition: 0.3s;
  height: 80px;
}
/*# sourceMappingURL=style.css.map */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .menu_wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    background: rgba(255, 255, 255, 0);
  }
  .menu_wrapper .logo {
    width: 40%;
  }
  .menu_wrapper .nav {
    width: 60%;
    display: none;
  }
  .menu_wrapper .contato {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon {
    height: 40px;
    width: 40px;
    background: #f50;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon i {
    color: #fff;
  }
  .menu_wrapper .contato .tel {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
  }
  .menu_wrapper .contato .tel .text {
    font-size: 10px;
    color: #f50;
    text-transform: uppercase;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .menu_wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    background: rgba(255, 255, 255, 0);
  }
  .menu_wrapper .logo {
    width: 40%;
  }
  .menu_wrapper .nav {
    width: 60%;
    display: none;
  }
  .menu_wrapper .contato {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon {
    height: 40px;
    width: 40px;
    background: #f50;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon i {
    color: #fff;
  }
  .menu_wrapper .contato .tel {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
  }
  .menu_wrapper .contato .tel .text {
    font-size: 10px;
    color: #f50;
    text-transform: uppercase;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .menu_wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    background: rgba(255, 255, 255, 0);
  }
  .menu_wrapper .logo {
    width: 40%;
  }
  .menu_wrapper .nav {
    width: 60%;
    display: none;
  }
  .menu_wrapper .contato {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon {
    height: 40px;
    width: 40px;
    background: #f50;
    border-radius: 50px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_wrapper .contato .circle_icon i {
    color: #fff;
  }
  .menu_wrapper .contato .tel {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
  }
  .menu_wrapper .contato .tel .text {
    font-size: 10px;
    color: #f50;
    text-transform: uppercase;
  }
}
/*# sourceMappingURL=responsive.css.map */
.tipos_wrapper {
  min-height: 300px;
  height: auto;
  padding: 50px 0;
  background: #f8f8f8;
}

.tipos_wrapper .tipos_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tipos_wrapper .tipos_container .title_tipos {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  align-self: center;
  margin: 0 auto;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.tipos_wrapper .tipos_container .tipos_box_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
}

.tipos_wrapper .tipos_container .tipos_box_container .box {
  height: 200px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
}

.tipos_wrapper .tipos_container .tipos_box_container .box .image_container {
  height: 100px;
  width: 100px;
  background: #ddd;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tipos_wrapper .tipos_container .tipos_box_container .box .image_container i {
  font-size: 25px;
}

.tipos_wrapper .tipos_container .tipos_box_container .box .tipe_name {
  height: 50px;
  margin-top: 20px;
}

.tipos_wrapper .tipos_container .tipos_box_container .box:last-child {
  margin-right: 0;
}

.tipos_wrapper .tipos_container .image_example .title_example {
  position: relative;
  text-align: center;
  display: flex;
  align-self: center;
  margin: 0 auto;
  font-size: 2rem;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 50px;
}

.tipos_wrapper .tipos_container .image_example img {
  width: 90%;
}
/*# sourceMappingURL=style.css.map */
